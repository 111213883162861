/*  ____                  ________ 
   / __ \____  ____ ___  / ____/ / 
  / / / / __ \/ __ `__ \/ / __/ /  
 / /_/ / /_/ / / / / / / /_/ / /___
/_____/\____/_/ /_/ /_/\____/_____*/

import "../core/functions"
import "../core/constants"
// import "../core/helpers" //Acá está la inicialización de stats.js, comentado porque lo paso a prod
import "../core/ui"
import Credits from '../components/credits'
import Preloader from "../components/preloader"
import Lenis from '@studio-freight/lenis'
import {
    DomGlPost // threejs setup with postprocessor connected with effect composer
} from "../dom_gl/index"
class App extends DomGlPost {
    constructor() {
        super()
        this.preloader = new Preloader()
        this.credits = new Credits("🌐 Hello Friend!")
        this.setLenis()
        // <- domgl event
        window.event.on('animate', () => this.animate())
        window.event.on('resize', () => this.resize())
        this.resize()
    }

    setLenis() {
        this.lenis = new Lenis({
            duration: .1,
            lerp: this.lerp,
            orientation: 'vertical',
            gestureOrientation: 'vertical',
            smoothWheel: true,
            smoothTouch: true
        })
    }
    
    loaded() {
        this.preloader.hide()
        this.animate()
    }
    
    animate() {
        const time = new Date().getTime() * .1
        // requestAnimationFrame(() => this.animate())
        this.render(time) // <- domgl
        this.lenis.raf(time)
    }

}

const _app = new App()
window.onload = _app.loaded()
window.onpopstate = (event) => {
    window.location.reload()
}